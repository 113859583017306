@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;400&display=swap");

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media only screen and (max-width: 600px) {
  .slick-slider .slick-track {
    width: 9000px !important;
  }
}
@media only screen and (max-width: 600px) {
  .slick-initialized .slick-slide {
    display: block;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
